// space generation variables
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 140, 160, 180, 200); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // all positions

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

@each $space in $spaceamounts {
  .mx-#{$space} {
    margin: 0 #{$space}px !important;
  }

  .px-#{$space} {
    padding: 0 #{$space}px !important;
  }

  .my-#{$space} {
    margin: #{$space}px 0 !important;
  }

  .py-#{$space} {
    padding: #{$space}px 0 !important;
  }
}

// Fonts
$size: .5;
@while ($size <= 2) {
  @for $weight from 1 through 8 {
    .display-type-#{$size * 100}-#{$weight * 100} {
      font-size: #{$size}rem;
      line-height: 1.5;
      font-weight: #{$weight * 100};
    }
  }

  $size: $size + .05;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}
