@use '@angular/material' as mat;
@import "variables";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core($rhbnb-typography);

// Include the default theme styles.
@include mat.all-component-themes($rhbnb-theme-with-foreground);

.md-drppicker td.active, .md-drppicker td.available.in-range.active, button.active, .md-drppicker td.active:hover {
  background-color: mat.get-color-from-palette(map-get($rhbnb-theme-with-foreground, primary))!important;
  color: white !important;
}

// ROOT colors
:root {
  --page-title-color: #{$rhbnb-page-title-color};
  --primary-color: #{$rhbnb-info-color};
  --secundary-color: #{$rhbnb-success-color};
  --info-color: #{$rhbnb-info-color};
  --success-color: #{$rhbnb-success-color};
  --error-color: #{$rhbnb-error-color};
  --created-color: #{$rhbnb-created-color};
  --warning-color: #{$rhbnb-warning-color};
  --foreground-color: #{$foreground-color};
}
