// >> NgSelect Theme
@import "~@ng-select/ng-select/themes/material.theme.css"

.ng-select.ng-select-focused .ng-select-container:after
  border-color: var(--primary-color)

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after, .ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after
  border-color: var(--primary-color)

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder
  color: var(--primary-color)

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow
  color: var(--primary-color)

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value
  background-color: var(--primary-color)

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected
  color: var(--primary-color)

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected
  color: var(--primary-color)
// << NgSelect Theme

//  Fix mat input outline prefix
.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix
  top: unset!important

.badge
  display: inline-block
  padding: .35em .65em
  font-size: .75em
  font-weight: 700
  line-height: 1
  color: #fff
  text-align: center
  white-space: nowrap
  vertical-align: baseline
  border-radius: .25rem
  background: var(--primary-color)

.badge-notification
  position: absolute
  font-size: .6rem
  margin-top: -.1rem
  margin-left: -.5rem
  padding: .2em .45em
