// ########################################################
// ########### CUSTOM FONT FAMILIES #######################
// ########################################################

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 800
  src: url(../assets/fonts/Sailec_Bold.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 700
  src: url(../assets/fonts/Sailec_Bold.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 600
  src: url(../assets/fonts/Sailec_Bold.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 500
  src: url(../assets/fonts/Sailec_Bold.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 300
  src: url(../assets/fonts/Sailec_Light.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 400
  src: url(../assets/fonts/Sailec_Medium.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 200
  src: url(../assets/fonts/Sailec_Thin.ttf) format("truetype")
  font-display: swap

@font-face
  font-family: Sailec
  font-style: normal
  font-weight: 100
  src: url(../assets/fonts/Sailec_Thin.ttf) format("truetype")
  font-display: swap
