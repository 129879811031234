@font-face {
    font-family: "flaticon";
    src: url("flaticon.eot");
    src: url("flaticon.eot?#iefix") format("embedded-opentype"),
    url("flaticon.ttf") format("truetype"),
    url("flaticon.svg#flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
}

.flaticon {
    display: inline;
    font-family: "flaticon", serif;
    font-size: 2em;
    margin-right: 10px;
    vertical-align: middle;
}

.flaticon-adventure:before {
    content: "\e001";
}

.flaticon-air-conditioner:before {
    content: "\e002";
}

.flaticon-air-transport:before {
    content: "\e003";
}

.flaticon-airplane-around-earth:before {
    content: "\e004";
}

.flaticon-antique-balcony:before {
    content: "\e005";
}

.flaticon-apartments-prices:before {
    content: "\e006";
}

.flaticon-audio-equipment:before {
    content: "\e007";
}

.flaticon-backpack:before {
    content: "\e008";
}

.flaticon-balcony:before {
    content: "\e009";
}

.flaticon-bathtub:before {
    content: "\e00a";
}

.flaticon-beach-umbrella-and-sun:before {
    content: "\e00b";
}

.flaticon-beach:before {
    content: "\e00c";
}

.flaticon-bed:before {
    content: "\e00d";
}

.flaticon-birthday-cake:before {
    content: "\e00e";
}

.flaticon-breathing-rescue:before {
    content: "\e00f";
}

.flaticon-building:before {
    content: "\e010";
}

.flaticon-businessman-with-suitcase-entering-by-a-rotatory-door:before {
    content: "\e011";
}

.flaticon-calendar-1:before {
    content: "\e012";
}

.flaticon-calendar-2:before {
    content: "\e013";
}

.flaticon-calendar-3:before {
    content: "\e014";
}

.flaticon-calendar:before {
    content: "\e015";
}

.flaticon-cctv:before {
    content: "\e016";
}

.flaticon-chalet:before {
    content: "\e017";
}

.flaticon-clothes-hanger:before {
    content: "\e018";
}

.flaticon-computer-monitor:before {
    content: "\e019";
}

.flaticon-cooker:before {
    content: "\e01a";
}

.flaticon-cooking-1:before {
    content: "\e01b";
}

.flaticon-cooking:before {
    content: "\e01c";
}

.flaticon-cooler:before {
    content: "\e01d";
}

.flaticon-customer:before {
    content: "\e01e";
}

.flaticon-dancing:before {
    content: "\e01f";
}

.flaticon-detached:before {
    content: "\e020";
}

.flaticon-dinner:before {
    content: "\e021";
}

.flaticon-disabled:before {
    content: "\e022";
}

.flaticon-distance:before {
    content: "\e023";
}

.flaticon-diving:before {
    content: "\e024";
}

.flaticon-domain-registration:before {
    content: "\e025";
}

.flaticon-double-door-opened:before {
    content: "\e026";
}

.flaticon-dvd-player:before {
    content: "\e027";
}

.flaticon-electric-guitar:before {
    content: "\e028";
}

.flaticon-english-receptionist:before {
    content: "\e029";
}

.flaticon-entrance:before {
    content: "\e02a";
}

.flaticon-family-of-four-in-front-of-their-home:before {
    content: "\e02b";
}

.flaticon-family-silhouette:before {
    content: "\e02c";
}

.flaticon-fireworks:before {
    content: "\e02d";
}

.flaticon-fishing:before {
    content: "\e02e";
}

.flaticon-flamenco-couple-dancing:before {
    content: "\e02f";
}

.flaticon-for-rent:before {
    content: "\e030";
}

.flaticon-full-bed:before {
    content: "\e031";
}

.flaticon-gamepad-controller:before {
    content: "\e032";
}

.flaticon-guitar:before {
    content: "\e033";
}

.flaticon-hamburger:before {
    content: "\e034";
}

.flaticon-hotel-letter-h-sign-inside-a-black-rounded-square:before {
    content: "\e035";
}

.flaticon-house-1:before {
    content: "\e036";
}

.flaticon-house-2:before {
    content: "\e037";
}

.flaticon-house-building:before {
    content: "\e038";
}

.flaticon-house:before {
    content: "\e039";
}

.flaticon-iron-1:before {
    content: "\e03a";
}

.flaticon-iron-electric-heat:before {
    content: "\e03b";
}

.flaticon-iron:before {
    content: "\e03c";
}

.flaticon-jack-russell-terrier:before {
    content: "\e03d";
}

.flaticon-jbl-audio-amplification-equipment:before {
    content: "\e03e";
}

.flaticon-jewelry-set:before {
    content: "\e03f";
}

.flaticon-jewelry:before {
    content: "\e040";
}

.flaticon-kids-couple:before {
    content: "\e041";
}

.flaticon-list:before {
    content: "\e042";
}

.flaticon-living-room-books-group:before {
    content: "\e043";
}

.flaticon-location-pin:before {
    content: "\e044";
}

.flaticon-love-beach:before {
    content: "\e045";
}

.flaticon-lovely-mugs:before {
    content: "\e046";
}

.flaticon-map-of-roads:before {
    content: "\e047";
}

.flaticon-meat:before {
    content: "\e048";
}

.flaticon-medal:before {
    content: "\e049";
}

.flaticon-milk:before {
    content: "\e04a";
}

.flaticon-minisplit:before {
    content: "\e04b";
}

.flaticon-money-1:before {
    content: "\e04c";
}

.flaticon-money:before {
    content: "\e04d";
}

.flaticon-monitor:before {
    content: "\e04e";
}

.flaticon-newlyweds:before {
    content: "\e04f";
}

.flaticon-no-smoking-sign:before {
    content: "\e050";
}

.flaticon-old-typical-phone:before {
    content: "\e051";
}

.flaticon-parked-car:before {
    content: "\e052";
}

.flaticon-parking-sign:before {
    content: "\e053";
}

.flaticon-parking:before {
    content: "\e054";
}

.flaticon-pastry-cream:before {
    content: "\e055";
}

.flaticon-payment-method:before {
    content: "\e056";
}

.flaticon-person-enjoying-jacuzzi-hot-water-bath:before {
    content: "\e057";
}

.flaticon-person-lying-on-a-beach-under-an-umbrella:before {
    content: "\e058";
}

.flaticon-phone-contact:before {
    content: "\e059";
}

.flaticon-photo-camera:before {
    content: "\e05a";
}

.flaticon-picnic:before {
    content: "\e05b";
}

.flaticon-pipe:before {
    content: "\e05c";
}

.flaticon-plant:before {
    content: "\e05d";
}

.flaticon-plug:before {
    content: "\e05e";
}

.flaticon-power-cord:before {
    content: "\e05f";
}

.flaticon-prize:before {
    content: "\e060";
}

.flaticon-refrigerator-with-freezer:before {
    content: "\e061";
}

.flaticon-relaxing-procedure:before {
    content: "\e062";
}

.flaticon-rent-a-car-service:before {
    content: "\e063";
}

.flaticon-rent-a-car:before {
    content: "\e064";
}

.flaticon-road:before {
    content: "\e065";
}

.flaticon-romantic-date:before {
    content: "\e066";
}

.flaticon-sand-castle:before {
    content: "\e067";
}

.flaticon-security-camera-1:before {
    content: "\e068";
}

.flaticon-security-camera:before {
    content: "\e069";
}

.flaticon-shower:before {
    content: "\e06a";
}

.flaticon-sink:before {
    content: "\e06b";
}

.flaticon-small-refrigerator:before {
    content: "\e06c";
}

.flaticon-smoking-barbecue:before {
    content: "\e06d";
}

.flaticon-snorkel:before {
    content: "\e06e";
}

.flaticon-strongbox-door:before {
    content: "\e06f";
}

.flaticon-student-in-front-of-a-stack-of-books:before {
    content: "\e070";
}

.flaticon-suitcase:before {
    content: "\e071";
}

.flaticon-sunbed-1:before {
    content: "\e072";
}

.flaticon-sunbed:before {
    content: "\e073";
}

.flaticon-swimming-pool:before {
    content: "\e074";
}

.flaticon-taking-care-of-disabled-people:before {
    content: "\e075";
}

.flaticon-task:before {
    content: "\e076";
}

.flaticon-taxi:before {
    content: "\e077";
}

.flaticon-telephone:before {
    content: "\e078";
}

.flaticon-tent:before {
    content: "\e079";
}

.flaticon-terrace:before {
    content: "\e07a";
}

.flaticon-tickets:before {
    content: "\e07b";
}

.flaticon-toaster:before {
    content: "\e07c";
}

.flaticon-tourist:before {
    content: "\e07d";
}

.flaticon-transport:before {
    content: "\e07e";
}

.flaticon-two-people-under-an-umbrella:before {
    content: "\e07f";
}

.flaticon-viral-marketing:before {
    content: "\e080";
}

.flaticon-walking-with-dog:before {
    content: "\e081";
}

.flaticon-washing-machine:before {
    content: "\e082";
}

.flaticon-wifi:before {
    content: "\e083";
}

.flaticon-yatch:before {
    content: "\e084";
}
