.spacer
  flex: 1 1 auto

.short-space
  width: 10px

@media only screen and (max-width: 760px)
  .dialog-responsive
    width: 100% !important
    height: 100% !important
    max-width: 100% !important
    max-height: 100% !important

    .mat-dialog-container
      border-radius: 0 !important
