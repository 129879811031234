// Import Bootstrap
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';

// Swiper CSS
@import '~swiper/swiper-bundle';

/* You can add global styles to this file, and also import other style files */
@import '~microtip/microtip.css';

// Customize
@import "sass/customize";

// Responsive classes
@import "sass/responsive";

// Responsive classes
@import "sass/components";

// Fonts
@import "sass/font-families";

// Utilities
@import "sass/utilities";

// Flat icons
@import "assets/icons/flaticons/rentalho/flaticon.css";

// Sobrescribe los breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 599px,
  md: 600px,
  lg: 960px,
  xl: 1280px,
  xxl: 1920px
);

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Sailec-Medium, Roboto, "Helvetica Neue", sans-serif;
  color: var(--foreground-color);
}

.bz-bb {
  box-sizing: border-box !important;
}

.title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 22px;
}

.subtitle {
  font-size: large;
  margin-bottom: 10px;
}

@mixin blue-down($height: 25px, $bottom: -6px) {
  color: var(--primary-color);
  position: relative;

  &:after {
    content: '';
    background-color: #3c65f5;
    height: $height;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: $bottom;
    z-index: -1;
    opacity: 0.1;
  }
}

h1 > span.blue-down {
  @include blue-down;
}

a > span.blue-down {
  @include blue-down(10px, -1px);
}

.reset {
  all: unset;
}
