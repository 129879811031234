.full-width
  width: 100%

.truncate
  width: 250px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.small-avatar
  width: 70px
  height: 70px
  object-fit: cover
  border-radius: 50%

.load-animate, .ng-lazyloading
  animation-duration: 1s
  animation-name: load-animate-light
  animation-direction: alternate
  animation-iteration-count: infinite
  animation-timing-function: ease-in-out
  animation-fill-mode: forwards
  background: #3a3a3a

  &.dark
    animation-name: load-animate-dark!important

@keyframes load-animate-dark
  0%
    opacity: 0.19049773755656108

  100%
    opacity: 0.45384615384615385

@keyframes load-animate-light
  0%
    opacity: 0.09049773755656108

  100%
    opacity: 0.15384615384615385

.status

  &.confirmed
    background-color: var(--success-color)

  &.pending
    background-color: var(--info-color)

  &.canceled
    background-color: var(--error-color)
